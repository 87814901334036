<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="3">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Он:"
            label-for="input-name"
          >
            <b-select :options="years" v-model="form.year"></b-select>
          </b-form-group>
          <b-form-group
            id="input-group-name"
            label="Сар:"
            label-for="input-name"
          >
            <b-select :options="months" v-model="form.month"></b-select>
          </b-form-group>
          <b-button variant="primary" @click="loadData()">Шүүх</b-button>
        </b-card>
      </b-col>
      <b-col sm="9">
        <b-card>
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <table class="table">
            <tbody>
              <tr>
                <td><strong>1. Зээлийн үлдэгдэл</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[0][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>2. 5 ангилалын зээлийн үлдэгдэл</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group" v-if="items.length > 0">
                    <li
                      class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1 "
                      :key="index"
                      v-for="(item, index) in items[2]"
                      :class="{ 'font-bold': item[0] == 0 }"
                    >
                      <span class="pull-left"
                        ><span class="ml-3" v-if="item[5] != null">- </span
                        >{{ item[1] }}
                        <small v-if="item[6]">({{ item[6] }})</small>
                      </span>
                      <span>{{ item[4] | currecry }}₮</span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>3. Хуримтлагдсан хүү</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group">
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Бодогдсон</span>
                      <span
                        >{{
                          items.length > 0 ? items[3][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Төлөгдсөн</span>
                      <span
                        >{{
                          items.length > 0 ? items[14][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>4. 5 ангилалын хуримтлагдсан хүү</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group" v-if="items.length > 0">
                    <li
                      class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1 "
                      :key="index"
                      v-for="(item, index) in items[4]"
                      :class="{ 'font-bold': item[0] == 0 }"
                    >
                      <span class="pull-left"
                        ><span class="ml-3" v-if="item[5] != null">- </span
                        >{{ item[1]
                        }}<small v-if="item[6]">({{ item[6] }})</small></span
                      >
                      <span>{{ item[4] | currecry }}₮</span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>5. Эрсдэлийн сан</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group" v-if="items.length > 0">
                    <li
                      class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1 "
                      :key="index"
                      v-for="(item, index) in items[5]"
                      :class="{ 'font-bold': item[0] == 0 }"
                    >
                      <span class="pull-left">
                        <span class="ml-3" v-if="item[5] != null">- </span>
                        {{ item[1] }}
                        <small v-if="item[6]"> ({{ item[6] }}) </small>
                        <small v-if="item.length > 7" class="font-bold">
                          - {{ item[7] }}%</small
                        >
                      </span>
                      <span> {{ item[4] | currecry }}₮ </span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>6. Зөрүү</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group">
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Эргэн төлөлтийн зөрүү</span>
                      <span
                        >{{
                          items.length > 0 ? items[6][0][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Данс хооронд</span>
                      <span
                        >{{
                          items.length > 0 ? items[6][1][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>7. Гүйлгээний шимтгэл</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[7][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>8. Qpay шимтгэл</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[8][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>9. SMS зардал</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[9][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>10. Нэмэгдүүлсэн хүү /тооцсоноор/</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[10][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>11. Шимтгэл /төлөлт/</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  {{ items.length > 0 ? items[11][0] : 0 | currecry }}₮
                </td>
              </tr>
              <tr>
                <td><strong>12. Олголтын шимтгэл</strong></td>
                <td class="text-right" style="padding-right: 200px;">
                  <ul class="list-group">
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Бодогдсон</span>
                      <span
                        >{{
                          items.length > 0 ? items[12][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                    <li class="list-group-item border-0 pl-0 pr-0 pt-1 pb-1">
                      <span class="pull-left">Төлөгдсөн</span>
                      <span
                        >{{
                          items.length > 0 ? items[13][0] : 0 | currecry
                        }}₮</span
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Report.list.month",
  components: {
    axios,
  },
  data: function() {
    return {
      isLoading: false,
      form: {
        year: moment().year(),
        month: moment().month() + 1,
      },
      columns: [
        { label: "", key: "name" },
        { label: "Дүн", key: "amount", class: "text-right" },
        { label: "Тоо", key: "count", class: "text-right" },
      ],
      items: [],
    };
  },
  computed: {
    years: function() {
      var result = [];
      for (var i = 2018; i <= moment().year(); i++) result.push(i);
      return result;
    },
    months: function() {
      var result = [];
      for (var i = 1; i <= 4; i++)
        result.push({ value: i * 3, text: i + "-р улирал" });
      return result;
    },
  },
  created() {
    var mult =
      parseInt(this.$data.form.month / 3) +
      (this.$data.form.month % 3 > 0 ? 1 : 0);
    this.$data.form.month = mult * 3;
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "ReportWebService/get_season_report",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              year: this.$data.form.year,
              month: this.$data.form.month,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            if (response.body.status == 500) {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            } else if (response.body.status != 1) {
              this.$data.items = response.body.data;
              this.classGroupGen(2);
              this.classGroupGen(4);
              this.classGroupGen(5);
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    classGroupGen: function(_index) {
      let a = this.$data.items[_index];
      let added_index = [];
      let tmp_index = 0;
      let tmp_gname = null;
      let tmp_sum = 0;
      for (var i in a) {
        var _item = a[i];
        if (tmp_gname != _item[5]) {
          added_index.push({
            index: tmp_index,
            name: tmp_gname,
            value: tmp_sum,
          });
          tmp_index = i;
          tmp_gname = _item[5];
          tmp_sum = 0;
        }
        tmp_sum += _item[4];
      }
      added_index.push({
        index: tmp_index,
        name: tmp_gname,
        value: tmp_sum,
      });
      let offset = 0;
      for (let i in added_index) {
        if (added_index[i].name != null) {
          this.$data.items[_index].splice(added_index[i].index, 0, [
            0,
            added_index[i].name,
            null,
            null,
            added_index[i].value,
            null,
          ]);
          offset++;
        }
      }
    },
  },
};
</script>
